<template>
  <div class="wrap">
    <div>
      <el-upload
        class="upload-resume"
        drag
        action="/api/kapin_ee_admin/auth/v1/excel_resume/"
        multiple
        :before-upload="beforeUpload"
        :on-success="resume_success"
        accept=".xls"
        :data="excel_name"
        :headers="header"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将Excel文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          只能上传 Excel 文件，且不超过10MB
        </div>
      </el-upload>

      <!-- 表格 -->
      <div class="log_table">
        <div class="log_title">导入简历记录</div>
        <el-table :data="tableData" stripe style="width: 100%; font-size: 14px">
          <el-table-column prop="create_time" label="日期"></el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-tag
                size="mini"
                :type="scope.row.status == 1 ? 'success' : 'danger'"
                >{{ scope.row.status == 1 ? "成功" : "失败" }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="resume_all_count"
            label="总条数"
          ></el-table-column>
          <el-table-column
            prop="resume_valid_count"
            label="有效数"
          ></el-table-column>
          <el-table-column
            prop="resume_invalid_count"
            label="无效数"
          ></el-table-column>
          <el-table-column prop="excel_name" label="文件名"></el-table-column>
        </el-table>
      </div>
      <el-dialog
        title="未成功上传的简历"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <el-table
          :data="tableData_list"
          stripe
          style="width: 100%; font-size: 14px"
        >
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
 
    <el-button class="btn" size="mini" @click="daochu"
      >导出简历手机号</el-button
    > 
 
    

    <div class="wrap_box1">
      <el-input
        v-model.number="value1"
        placeholder="请输入刷新简历的手机号"
      ></el-input>
      <el-button class="btn" size="mini" @click="shuaxin">刷新</el-button>
    </div>


  </div>
</template>

<script>
import {
  upload_resume,
  show_excel,
  daochu_phone,
  shuaxin_resume
} from "@/api/hr24_api/api_all";
export default {
  data() {
    return {
      value1: '',//手机号
      uploadName: "",
      uploadFile: undefined,
      tableData: [],
      excel_name: {
        excel_name: "",
      },
      dialogVisible: false,
      tableData_list: [],
    };
  },
  computed: {
    header: function () {
      return { token: sessionStorage.getItem("token") };
    },
  },
  mounted() {},
  methods: {

    //刷新 简历
    shuaxin() {
      shuaxin_resume({phone:this.value1}).then((res) => {
        if (!res.code) {
          this.value1 = ''
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
        console.log(res)
      })
    },


    daochu() {
      console.log("导出手机号");
      daochu_phone().then((res) => {
        console.log(res);
        
        const element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data.resume_phone))  //res.data.data是接口返回的数据
        element.setAttribute('download', '手机号')
        element.style.display = 'none'
        element.click() 

        if (res.code == 0) {
        }
      });
    },
    handleClose() {
      this.tableData_list = [];
      this.dialogVisible = false;
    },
    init() {
      show_excel().then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data.return_list;
        }
      });
    },
    beforeUpload(file) {
      console.log(file);
      this.excel_name.excel_name = file.name;
    },
    resume_success(res) {
      this.init();

      console.log(res);
      if (res.code == 0) {
        if (res.data.mistake_list.length > 0) {
          this.tableData_list = res.data.mistake_list;
          this.dialogVisible = true;
        }
        this.$message.success("简历上传成功");
      }
    },
    file_success(e) {
      this.$message({
        message: "上传文件成功，请不要重复提交",
        type: "success",
      });
    },
  },

  created() {
    this.init();
  },
};
</script>

<style scoped>
.wrap > div {
  display: flex;
  justify-content: space-around;
}
.log_title {
  padding: 20px 20px 20px 10px;
  color: #606266;
  font-weight: 600;
}
.log_table {
  height: 55vh;
  width: 85%;
}
.el-upload__tip {
  font-size: 12px;
  color: #606266;
  margin-top: 20px;
  margin-left: 0px;
}

.el-upload__text {
  padding-left: 10px;
}

.upload-resume {
  padding: 30px 30px 30px 50px;
  /* background: red; */
}

.wrap_box1 {
  margin-top: 20px;
width:  300px;
}
.el-input {
  width: 100%;
  margin-right: 10px;
}
 
</style>
